<template>
  <div class="authorization-content">
    <!-- 导航栏 -->
    <NavBar title="运费代收授权"></NavBar>

    <div class="content">
      <Tabs line-width="50%" sticky offset-top="0.44rem">
        <!-- 待授权 -->
        <Tab title="待授权">
          <RadioGroup v-model="radio" class="platform-list">
            <Radio class="platform" name="1" icon-size="0.16rem"
              >平台配置—基地设置的企业名称
              <template #icon="props">
                <Icon :name="props.checked ? 'xz-xy' : 'weixuan'"></Icon>
              </template>
            </Radio>
            <Radio class="platform" name="2" icon-size="0.16rem"
              >平台配置—基地设置的企业名称
              <template #icon="props">
                <Icon :name="props.checked ? 'xz-xy' : 'weixuan'"></Icon>
              </template>
            </Radio>
            <Radio class="platform" name="3" icon-size="0.16rem"
              >平台配置—基地设置的企业名称
              <template #icon="props">
                <Icon :name="props.checked ? 'xz-xy' : 'weixuan'"></Icon>
              </template>
            </Radio>
          </RadioGroup>
          <div style="position:relative; height:0.72rem;">
            <div class="btn-box">
              <Button size="large" type="primary">授权</Button>
            </div>
          </div>
        </Tab>

        <!-- 已授权 -->
        <Tab title="已授权">
          <List
            class="list"
            v-model="loading"
            :error.sync="error"
            error-text="请求失败，点击重新加载"
            :finished="finished"
            finished-text="没有更多了"
            @load="getListData"
          >
            <ul class="platform-list">
              <li class="platform">
                <div class="icon-box">
                  <Icon name="qiye"></Icon>
                </div>
                浙江洪湖集团有限公司
              </li>
            </ul>
          </List>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import NavBar from '@/components/NavBar/index';
import { Tab, Tabs, RadioGroup, Radio, Button, Dialog, List } from 'vant';
import { getBillSearch } from '@api/waybill';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  components: {
    NavBar,
    Tab,
    Tabs,
    RadioGroup,
    Radio,
    Button,
    List
  },
  setup(_, ctx) {
    const router = useRouter();

    // 改变页面背景颜色
    onMounted(() => {
      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
    });

    Dialog.confirm({
      message: '司机认证未通过，无法接单！',
      confirmButtonText: '点击认证',
      closeOnClickOverlay: true
    })
      .then(() => {
        router.push(PageEnum.BASE_DRIVER_AUTH);
      })
      .catch(() => {});

    Dialog.alert({
      className: 'authorization-alert',
      message: '在线账户未开通，请联系平台管理员',
      closeOnClickOverlay: true,
      confirmButtonColor: '#8E8E93',
      confirmButtonText: '关闭'
    })
      .then(() => {})
      .catch(() => {});

    // 当前单选值
    const radio = ref('1');

    // 遮罩层显示
    const show = ref(true);

    // 列表加载状态
    const authorizedList = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const error = ref(false);
    const pageNum = ref(1);

    // 获取列表数据
    const getListData = async () => {
      // 获取运单列表接口
      const [e, res] = await ctx.root.$await(
        getBillSearch({
          pageNum: pageNum.value,
          pageSize: 10
        })
      );
      if (e) {
        // 如果请求失败，显示失败文案
        setTimeout(() => {
          error.value = true;
        }, 1000);
      } else {
        const data = res.data;
        if (data.records.length) {
          authorizedList.value = authorizedList.value.concat(data.records);
          pageNum.value++;
        } else {
          // 如果没有数据了，显示完成文案
          setTimeout(() => {
            finished.value = true;
          }, 1000);
        }
      }
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    };

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return { getListData, radio, show, loading, finished, error };
  }
});
</script>
<style lang="less" scoped>
.authorization-content {
  .content {
    .platform-list {
      padding: 0.16rem;
      .platform {
        background: #fff;
        border-radius: 0.04rem;
        box-shadow: 0px 0.02rem 0.06rem 0px rgba(223, 233, 245, 0.2);
        height: 0.56rem;
        padding-left: 0.16rem;
        margin-bottom: 0.12rem;
        font-size: 0.16rem;
        display: flex;
        align-items: center;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        .icon-box {
          width: 0.24rem;
          height: 0.24rem;
          background: #e7f2fe;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.08rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.authorization-content {
  .van-radio__label {
    margin-left: 0.12rem;
  }
}
.authorization-alert {
  .van-button {
    background: #f6f8fa !important;
  }
}
</style>
